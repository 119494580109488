import React from 'react';
import { Box } from '@material-ui/core';

import ComingSoon from '../assets/construction.svg';

const index = () => {
  return (
    <Box
      minHeight="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box maxWidth="600px" width="100%" textAlign="center" margin="auto">
        <img src={ComingSoon} />
        <h1>Coming Soon</h1>
        <p>We are currently working hard building this page!</p>
      </Box>
    </Box>
  );
};

export default index;
